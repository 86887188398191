import { decorate, observable, action } from "mobx";
import { DodgeStore } from "./DodgeStore";
import { UserStore } from "./UserStore";

class RootStore {
  dodgeStore = new DodgeStore(null);
  userStore = new UserStore(null);

  constructor() {
    this.dodgeStore = new DodgeStore(this);
    this.userStore = new UserStore(this);
  }
}

let rootStore = new RootStore();

export const getRootStore = () => {
  return rootStore;
};
