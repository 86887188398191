import React, { useState } from "react";
import firebase from "firebase/app";
import Signup from "./User/Signup";
import Header from "./Header";
import { observer } from "mobx-react";
import Scoreboard from "./Scoreboard";
import Game from "./Game";
import Body from "./Body";
import { Form, Input, Upload, Button } from "antd";
import { BrowserRouter, Route } from "react-router-dom";
import LoginRegisterModal from "./Modals/LoginRegisterModal";
import { getRootStore } from "../Store/RootStore";
require("firebase/functions");
const rootStore = getRootStore();

const AccessDenied = () => {
  return (
    <div className="access-denied">
      <h2>
        You do not have access to this page. Please <u> login</u>
      </h2>
    </div>
  );
};
const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("newCharacter");
  return (
    <div className="admin-dashboard">
      <div className="sidebar">
        <div className="tab active" id="newCharacter">
          New character
        </div>
        <div className="tab disabled" id="stats">
          Player stats (Not available)
        </div>
      </div>
      <div className="tab-content">
        {activeTab === "newCharacter" && <CreateCharacter />}
      </div>
    </div>
  );
};

const CreateCharacter = () => {
  const [form] = Form.useForm();

  const [spritesheet, setSpritesheet] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const onFinish = (values: any) => {
    values.spritesheet = spritesheet;
    if (spritesheet !== null) {
      const create = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("createCharacter");
      setLoading(true);
      create(values).then(function (result) {
        setLoading(false);
        form.resetFields();
        // Read result of the Cloud Function.
        //var sanitizedMessage = result.data.text;
        // ...
      });
    }
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: "",
          frameWidth: 0,
          frameHeight: 0,
        }}
        onFinish={onFinish}
      >
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Frame width" name="frameWidth">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Frame Height" name="frameHeight">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Spritesheet">
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target && e.target.files) {
                let file = e.target.files[0];
                let reader = new FileReader();
                if (file instanceof Blob) {
                  reader.readAsDataURL(file);
                  reader.onloadend = () => {
                    if (reader.result) {
                      setSpritesheet(reader.result as string);
                    }
                  };
                }
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="create-character-btn"
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
const Home = observer(() => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Route exact path="/" component={Body} />
        <Route exact path="/challenge*" component={Body} />
        {rootStore.userStore.hasRole("admin") && (
          <Route exact path="/admin" component={AdminDashboard} />
        )}
      </BrowserRouter>

      {/*
      
      <div className="home-container body">
        {!rootStore.userStore.loggedIn ? (
          <>
            <Signup />
          </>
        ) : (
          <Game />
        )}
        <Scoreboard />
      </div>
    
    
    
      */}
    </>
  );
});

export default Home;
