import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { Form, Input, Radio, Upload } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  PhoneSignup,
  IPhoneSignup,
  checkIfUsernameIsUnique,
  checkIfUserHasUsername,
  registerUserName,
  SignupHelper,
  ISignupHelper,
} from "../../Firebase/Client";
import { findRenderedComponentWithType } from "react-dom/test-utils";

import { observer } from "mobx-react";
import { getRootStore } from "../../Store/RootStore";

interface IUserInfo {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}
interface IValidateStatus {
  validateStatus: any;
  errorMsg: string | null;
}

let signupHelper: ISignupHelper;

export enum LoginModalButtonsEnum {
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
}

export interface ILoginModal {
  buttonType: LoginModalButtonsEnum | null;
  setButtonType: Function;
}

const rootStore = getRootStore();

const LoginRegisterModal = observer(() => {
  const { visible, handleOk, handleCancel, showModal } = useModal();
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const onValuesChange = (data: any) => {
    //console.log("hello", data);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [
    usernameTakenStatus,
    setUsernameTakenStatus,
  ] = useState<IValidateStatus>({
    validateStatus: "success",
    errorMsg: null,
  });

  const [showUsernamePrompt, setShowUsernamePrompt] = useState<boolean>(false);
  const [signIn, setSignIn] = useState<boolean>(
    rootStore.dodgeStore.buttonType === LoginModalButtonsEnum.LOGIN
      ? true
      : false
  );
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [hasUsername, setHasUsername] = useState<boolean>(false);
  const [loginError, setLoginError] = useState(false);
  const [showingCodeInput, showCodeInput] = useState<boolean>(false);

  const onValueChange = (value: any) => {
    let inputName;
    Object.keys(value).map((key) => {
      inputName = key;
    });

    if (
      (!signIn && inputName === "password") ||
      inputName === "confirmPassword"
    ) {
      checkPasswordEquality();
    }
  };

  useEffect(() => {
    signupHelper = new SignupHelper();
  }, []);

  const signUp = () => {
    setLoading(true);

    if (!signIn) {
      if (usernameTakenStatus.validateStatus === "success") {
        signupHelper
          .signUp(
            form.getFieldValue("username"),
            form.getFieldValue("email"),
            form.getFieldValue("password")
          )
          .then(() => {
            rootStore.dodgeStore.setButtonType(null);

            setSuccess(true);
            setLoading(false);
          })
          .catch((ex: any) => {
            console.error(ex);
            setLoading(false);
            setError(ex);
          });
      }
    } else {
      signupHelper
        .signIn(form.getFieldValue("email"), form.getFieldValue("password"))
        .then(() => {
          rootStore.dodgeStore.setButtonType(null);

          setSuccess(true);
          setLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          setLoading(false);
          setLoginError(true);
        });
    }

    /*
    phoneSignup
      .signIn(form.getFieldValue("phoneNumber"))
      .then((result: any) => {
        setLoading(false);
        showCodeInput(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });

      */
  };

  const verifyCode = () => {
    /*
    setLoading(true);
    phoneSignup
      .verifyCode(form.getFieldValue("code"), form.getFieldValue("username"))
      .then((result: any) => {
        console.log(result);
        checkForUsername();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
      */
  };

  const checkForUsername = () => {
    checkIfUserHasUsername()
      .then(() => {
        //Has username
        setHasUsername(true);
      })
      .catch((err) => {
        if (err == 0) {
          //Does not have username
          setShowUsernamePrompt(true);
        } else {
          //Some error
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (hasUsername) {
      rootStore.userStore.setLoggedIn(true);
      setSuccess(true);
    }
  }, [hasUsername]);

  const registerUsername = () => {
    setLoading(true);
    checkIfUsernameIsUnique(form.getFieldValue("username"))
      .then(() => {
        setLoading(false);
        setUsernameTakenStatus({ validateStatus: "success", errorMsg: null });
        //registerUserName(form.getFieldValue("username"));
        //rootStore.userStore.setLoggedIn(true);
        //setSuccess(true);
      })
      .catch((err) => {
        if (err == 0) {
          setLoading(false);
          setUsernameTakenStatus({
            validateStatus: "error",
            errorMsg: "Username is already taken",
          });
        } else {
          setError(true);
        }
      });
  };

  const validateMessages = {
    required: "${label} is required",
    types: {
      email: "${label} is not valid email",
      number: "${label} is not a valid number",
    },
  };

  const [
    passwordErrorStatus,
    setPasswordErrorStatus,
  ] = useState<IValidateStatus>({ validateStatus: "success", errorMsg: "" });

  const checkPasswordEquality = () => {
    if (
      form.getFieldValue("password") !== form.getFieldValue("confirmPassword")
    ) {
      setPasswordErrorStatus({
        validateStatus: "error",
        errorMsg: "Passwords do not match",
      });
    } else {
      setPasswordErrorStatus({ validateStatus: "success", errorMsg: null });
    }
  };

  return (
    <>
      <Modal
        className="flyingblob-modal login-register"
        title={signIn ? "Login" : "Create an account"}
        visible={rootStore.dodgeStore.buttonType ? true : false}
        onOk={() => {
          form.resetFields();
          handleOk(null);
        }}
        onCancel={() => {
          form.resetFields();
          rootStore.dodgeStore.setButtonType(null);
        }}
        centered={true}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={
            {
              username: "",
              email: "",
              password: "",
              confirmPassword: "",
            } as IUserInfo
          }
          onValuesChange={onValueChange}
          validateMessages={validateMessages}
          onFinish={signUp}
        >
          <>
            {!signIn ? (
              <Form.Item
                label="Username"
                name="username"
                validateStatus={usernameTakenStatus.validateStatus}
                help={usernameTakenStatus.errorMsg || null}
                rules={[{ required: true }]}
              >
                <Input
                  onBlur={() => {
                    registerUsername();
                  }}
                />
              </Form.Item>
            ) : null}

            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email", required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              validateStatus={passwordErrorStatus.validateStatus}
              help={passwordErrorStatus.errorMsg || null}
              rules={[{ required: true }]}
            >
              <Input.Password minLength={4} />
            </Form.Item>
            {!signIn ? (
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                validateStatus={passwordErrorStatus.validateStatus}
                help={passwordErrorStatus.errorMsg || null}
                rules={[{ required: true }]}
              >
                <Input.Password minLength={4} />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                color="#FF5F3C"
                type="primary"
                htmlType="submit"
                className="btn-login-register"
                loading={loading}
              >
                {signIn ? "Sign in" : "Sign up"}
              </Button>
              <Button
                type="text"
                className="text-btn-login-register"
                onClick={() => {
                  setSignIn(!signIn);
                }}
              >
                {signIn ? "Create an account?" : "Sign in?"}
              </Button>
            </Form.Item>
          </>

          {error ? <p className="error">{error}</p> : null}
          {loginError ? (
            <p className="error">Email or password is wrong</p>
          ) : null}
        </Form>
      </Modal>
    </>
  );
});

export default LoginRegisterModal;

const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);

  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleOk = (submitFunction: Function | null) =>
    new Promise((resolve, reject) => {
      if (submitFunction) {
        submitFunction()
          .then(() => {
            resolve();
            closeModal();
          })
          .catch((ex: any) => {
            reject();
            setError(ex);
          });
      } else {
        closeModal();
      }
    });

  const handleCancel = () => {
    closeModal();
  };

  return { handleCancel, handleOk, showModal, closeModal, visible, error };
};
