//@ts-nocheck
import React, { Component } from "react";

import Phaser, { GameObjects } from "phaser";

import { IonPhaser } from "@ion-phaser/react";
import { AddScore } from "../Firebase/Client";
import { getCharacterManager, Character } from "../Store/CharacterManager";
import { getRootStore } from "../Store/RootStore";
const rootStore = getRootStore();
const characterManager = getCharacterManager();
let addScore = window.firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("addScore");

let increasePlays = window.firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("increasePlay");

const DisplayNewScore = (number) => {
  rootStore.userStore.blobs += 5;
  /*
  if (number > 0) {
    setTimeout(() => {
      rootStore.userStore.blobs++;
      DisplayNewScore(number - 1);
    }, 100);
  }
  */
};

const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

class Projectile {
  sprite: Phaser.Physics.Arcade.Sprite;
  target: Vector;
  direction: Vector;
  speed: number = 100;
  initialPosition: Vector;
  scene: GameplayScene;

  constructor(
    _scene: GameplayScene,
    _initialPosition: Vector,
    _target: Vector
  ) {
    this.initialPosition = _initialPosition;
    this.target = _target;
    this.sprite = _scene.physics.add.sprite(
      _initialPosition.x,
      _initialPosition.y,
      "projectile"
    );
    this.sprite.body.setSize(8, 8, 1, 1);
    this.scene = _scene;
    //scene.physics.add.collider(scene.player, this.sprite);
    this.direction = this.getDirectionVector();
  }

  //Returns unit vector
  getDirectionVector = () => {
    let distanceVector: Vector = {
      x: this.target.x - this.initialPosition.x,
      y: this.target.y - this.initialPosition.y,
    };
    let magnitude = Math.sqrt(
      distanceVector.x * distanceVector.x + distanceVector.y * distanceVector.y
    );

    let unitVector: Vector = {
      x: distanceVector.x / magnitude,
      y: distanceVector.y / magnitude,
    };

    return unitVector;
  };

  update() {
    if (this.sprite.active) {
      this.sprite.setVelocity(
        this.direction.x * this.speed,
        this.direction.y * this.speed
      );
      if (
        this.sprite.x > 256 ||
        this.sprite.x < 0 ||
        this.sprite.y > 192 ||
        this.sprite.y < 0
      ) {
        this.scene.score++;
        if (this.scene.score % 10 === 0) {
          this.scene.blobsEarned += 5;
          addScore(1);
          DisplayNewScore(5);
        }

        this.scene.scoreText.setText(`Score: ${this.scene.score}`);
        this.sprite.setPosition(1000, 1000);
        this.sprite.destroy(true);
      }
    }
  }
}

class Shooter {
  scene: GameplayScene;
  shootEvent: Time.TimerEvent;
  sprite: Phaser.Physics.Arcade.Sprite;
  flip: boolean;
  time: number;

  shootDelay: number;
  constructor(
    _scene: GameplayScene,
    position: Vector,
    _flip: boolean,
    shootStartDelay: number
  ) {
    this.scene = _scene;
    this.sprite = _scene.physics.add.sprite(position.x, position.y, "shooter");
    this.sprite.setDepth(1);
    this.time = this.scene.time.now;
    this.shootDelay = shootStartDelay;
    var idle = {
      key: "shooterIdle",
      frames: _scene.anims.generateFrameNumbers("enemyatlas", {
        start: 0,
        end: 1,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 6,
      repeat: -1,
    };
    var shoot = {
      key: "shoot",
      frames: _scene.anims.generateFrameNumbers("enemyatlas", {
        start: 8,
        end: 16,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 12,
      repeat: 0,
    };

    _scene.anims.create(idle);
    _scene.anims.create(shoot);
    this.flip = _flip;
    this.sprite.anims.load("shooterIdle");
    this.sprite.anims.load("shoot");
    this.sprite.flipX = _flip;
    /*
    this.shootEvent = _scene.time.addEvent({
      delay: shootStartDelay,
      repeat: 10,
      callback: this.shootProjectile,
    });
    */
  }

  shootProjectile = () => {
    if (this.sprite && this.sprite.anims) {
      this.shooting = true;
      if (this.animation === "shooterIdle") {
        this.sprite.anims.stop();
      }

      this.animation = "shoot";
      this.sprite.anims.setTimeScale(this.timeScale);
    }
  };

  animation: string = "shooterIdle";
  shooting: boolean = false;
  timeScale = 1;
  update() {
    if (this.sprite && this.sprite.anims) {
      if (
        this.scene.time.now - this.time >= this.shootDelay &&
        this.animation === "shooterIdle" &&
        this.sprite.anims.isPlaying
      ) {
        this.shootProjectile();
        //this.timeScale += 0.1;
        this.time = this.scene.time.now;
      }
      this.shootDelay -= 0.5;
      if (
        this.sprite.anims.currentAnim.key === "shoot" &&
        this.sprite.anims.getProgress() === 1
      ) {
        this.shooting = false;
        this.animation = "shooterIdle";
        this.sprite.anims.setTimeScale(1);
      }
      if (!this.sprite.anims.isPlaying) {
        this.sprite.anims.play(this.animation);
      }
      if (this.shooting) {
        if (this.sprite.anims.currentFrame.index === 6) {
          if (this.scene.player.active) {
            if (this.flip) {
              this.scene.spawnProjectile(
                { x: this.sprite.x - 10, y: this.sprite.y + 10 },
                { x: this.scene.player.x, y: this.scene.player.y }
              );
            } else {
              this.scene.spawnProjectile(
                { x: this.sprite.x + 10, y: this.sprite.y + 10 },
                { x: this.scene.player.x, y: this.scene.player.y }
              );
            }
          }

          this.shooting = false;
        }
      }
    }
  }
}

interface IAnimation {
  key: string;
  frames: any;
  frameRate;
  number;
}

class GameplayScene extends Phaser.Scene {
  player: Phaser.Physics.Arcade.Sprite;
  arcadePhysics = null;
  keyboard = null;
  spaceBar: Phaser.Input.Keyboard.Key = null;
  enter: Phaser.Input.Keyboard.Key;
  retrySprite: GameObjects.Sprite = null;
  overlay: GameObjects.RenderTexture = null;
  gameOver: boolean = false;
  map: Phaser.Tilemaps.Tilemap;
  tileset: Phaser.Tilemaps.Tileset;
  shooters: Array<Shooter> = [];
  projectiles: Array<Projectile> = [];
  projectilesSprites: Array<Phaser.Physics.Arcade.Sprite> = [];
  destroyedProjectiles: Array<Projectile> = [];
  activeCharacter: Character;
  score: number = 0;
  blobsEarned: number = 0;
  previousTouchPosition = { X: 0, Y: 0 };
  direction = { X: 0, Y: 0 };
  async preload() {
    //this.map = new Phaser.Tilemaps.Tilemap();
    var url;
    /*
    url =
      "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexvirtualjoystickplugin.min.js";
    this.load.plugin("rexvirtualjoystickplugin", url, true);
    */
    this.load.bitmapFont(
      "gamefont",
      "/assets/fonts/pixelfont_0.png",
      "/assets/fonts/pixelfont.xml"
    );

    this.activeCharacter = rootStore.userStore.character;
    this.load.spritesheet("default", "/assets/sprites/player.png", {
      frameWidth: 16,
      frameHeight: 19,
    });

    rootStore.userStore.characters.forEach((character) => {
      let spritesheet = new Image();
      spritesheet.src = character.base64Image;
      spritesheet.onload = () => {
        this.textures.addSpriteSheet(character.name, spritesheet, {
          frameWidth: character.frameWidth,
          frameHeight: character.frameHeight,
        });
      };
    });

    this.load.image("retry", "/assets/retry.png");
    this.load.tilemapTiledJSON("map", "/assets/tilemaps/level.json");
    this.load.image("tiles", "/assets/tilesets/tiles.png");
    this.load.image("projectile", "/assets/sprites/projectile.png");
    this.load.image("blob", "/assets/blob.png");

    this.load.spritesheet("enemyatlas", "/assets/sprites/enemyatlas.png", {
      frameWidth: 30,
      frameHeight: 30,
    });

    window.addEventListener("message", (data) => {
      if (
        data.origin === "https://flyingblob.com" ||
        data.origin === "http://localhost:3000"
      ) {
        if (data.data.type === "characterChange") {
          this.changeCharacter(data.data.data);
        }
      } else {
        console.error("Not allowed to change character");
      }
    });
    if (isMobile()) {
      this.input.on("pointerdown", (pointer) => {
        console.log(pointer);
        this.previousTouchPosition = { X: pointer.x, Y: pointer.y };
      });

      this.input.on("pointermove", (pointer) => {
        console.log(pointer.y - this.previousTouchPosition.Y);
        if (
          pointer.x - this.previousTouchPosition.X > 10 ||
          pointer.x - this.previousTouchPosition.X < -10
        ) {
          if (pointer.x - this.previousTouchPosition.X > 0) {
            this.direction.X = 1;
          } else {
            this.direction.X = -1;
          }
        } else {
          this.direction.X = 0;
        }

        if (
          pointer.y - this.previousTouchPosition.Y > 10 ||
          pointer.y - this.previousTouchPosition.Y < -10
        ) {
          if (pointer.y - this.previousTouchPosition.Y > 0) {
            this.direction.Y = 1;
          } else {
            this.direction.Y = -1;
          }
        } else {
          this.direction.Y = 0;
        }
      });

      this.input.on("pointerup", (pointer) => {
        this.direction = { X: 0, Y: 0 };
      });
    }
  }
  scoreText: Phaser.GameObjects.Text;
  blobsText: Phaser.GameObjects.Text;
  blobSprite: GameObjects.Sprite;
  create() {
    this.spaceBar = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.SPACE
    );

    this.enter = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.ENTER
    );

    var style = {
      font: "16px VT323",
      fill: "#fff",
      align: "center",
    };

    this.scoreText = this.add.bitmapText(
      100,
      10,
      "gamefont",
      `Score: ${this.score}`,
      12
    );

    this.blobsText = this.add
      .bitmapText(100, 30, "gamefont", `Blobs:`, 12)
      .setDepth(1)
      .setVisible(false);

    this.scoreText.setDepth(1);
    this.blobSprite = this.add
      .sprite(170, 36, "blob")
      .setDepth(1)
      .setVisible(false);
    this.retrySprite = this.add
      .sprite(128, 76, "retry")
      .setDepth(1)
      .setVisible(false)
      .setInteractive()
      .on("pointerover", () => {
        this.retrySprite.alpha = 0.5;
      })
      .on("pointerout", () => {
        this.retrySprite.alpha = 1;
      })
      .on("pointerdown", () => {
        this.resetGame();
      });

    this.arcadePhysics = new Phaser.Physics.Arcade.ArcadePhysics(this);
    this.map = this.add.tilemap("map");
    this.tileset = this.map.addTilesetImage("tiles");

    var walkDownConfig = {
      key: "walkDown",
      frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
        start: 0,
        end: 2,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 6,
    };
    var walkLeftConfig = {
      key: "walkLeft",
      frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
        start: 3,
        end: 5,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 6,
    };
    var walkUpConfig = {
      key: "walkUp",
      frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
        start: 6,
        end: 8,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 6,
    };
    var idleConfig = {
      key: "idle",
      frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
        start: 9,
        end: 10,
      } as Phaser.Types.Animations.GenerateFrameNumbers),
      frameRate: 6,
      repeat: -1,
    };

    var wizard1 = new Shooter(this, { x: 29, y: 17 }, false, 2000);
    var wizard2 = new Shooter(this, { x: 29, y: 162 }, false, 2500);
    var wizard3 = new Shooter(this, { x: 227, y: 17 }, true, 3000);
    var wizard4 = new Shooter(this, { x: 227, y: 162 }, true, 3500);

    this.shooters.push(wizard1);
    this.shooters.push(wizard2);
    this.shooters.push(wizard3);
    this.shooters.push(wizard4);

    this.anims.create(walkDownConfig);
    this.anims.create(walkLeftConfig);
    this.anims.create(walkUpConfig);
    this.anims.create(idleConfig);

    this.player = this.physics.add.sprite(100, 100, "player");

    this.createMap();
    this.loadPlayer();

    this.keyboard = this.input.keyboard.addKeys(
      "W, A, S, D, RIGHT, DOWN, LEFT, RIGHT, UP"
    );

    //this.changeCharacter();
    this.overlay = this.add.renderTexture(0, 0, 256, 192);
    this.overlay.fill(0x000000, 0.5);
    this.overlay.setVisible(false);
  }
  xPositions = [];
  xDirection = 0;
  handleMobileControls(): void {
    var pointer = this.input.activePointer;

    if (pointer.isDown) {
      let touchX = pointer.x;
      let xPositionsLength = this.xPositions.length;
      if (this.xPositions[xPositionsLength - 1] !== touchX) {
        this.xPositions.push(touchX);
      }

      if (this.xPositions.length > 3) {
        this.xPositions = [];
      } else if (this.xPositions.length >= 2) {
        if (
          this.xPositions[xPositionsLength - 1] -
            this.xPositions[xPositionsLength - 2] >
          0
        ) {
          this.xDirection = 1;
        } else {
          this.xDirection = -1;
        }
      }

      let touchY = pointer.y;
    } else {
      this.xDirection = 0;
    }
  }

  loadPlayer(): void {
    this.player.setCollideWorldBounds(true);
    this.player.anims.load("walkDown");
    this.player.anims.load("walkLeft");
    this.player.anims.load("walkUp");
    this.player.anims.load("idle");

    this.player.body.setSize(
      8,
      14,
      rootStore.userStore.character.frameWidth / 2 + 4,
      rootStore.userStore.character.frameHeight / 2 + 7
    );
  }

  resetGame(): void {
    var wizard1 = new Shooter(this, { x: 29, y: 17 }, false, 2000);
    var wizard2 = new Shooter(this, { x: 29, y: 162 }, false, 2500);
    var wizard3 = new Shooter(this, { x: 227, y: 17 }, true, 3000);
    var wizard4 = new Shooter(this, { x: 227, y: 162 }, true, 3500);

    this.shooters.push(wizard1);
    this.shooters.push(wizard2);
    this.shooters.push(wizard3);
    this.shooters.push(wizard4);
    this.retrySprite.setVisible(false);
    this.blobSprite.setVisible(false);
    this.overlay.setVisible(false);
    this.blobsText.setVisible(false);
    this.blobsEarned = 0;
    this.gameOver = false;
    this.score = 0;

    this.scoreText.setText(`Score: ${0}`);

    this.player = this.physics.add.sprite(100, 100, "player");

    this.loadPlayer();
  }

  changeCharacter(character: Character): void {
    this.activeCharacter = character;

    if (this.player) {
      this.anims.remove("walkDown");
      this.anims.remove("walkLeft");
      this.anims.remove("walkUp");
      this.anims.remove("walkRight");
      this.anims.remove("idle");

      var walkDownConfig = {
        key: "walkDown",
        frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
          start: 0,
          end: 2,
        } as Phaser.Types.Animations.GenerateFrameNumbers),
        frameRate: 6,
      };
      var walkLeftConfig = {
        key: "walkLeft",
        frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
          start: 3,
          end: 5,
        } as Phaser.Types.Animations.GenerateFrameNumbers),
        frameRate: 6,
      };
      var walkUpConfig = {
        key: "walkUp",
        frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
          start: 6,
          end: 8,
        } as Phaser.Types.Animations.GenerateFrameNumbers),
        frameRate: 6,
      };
      var idleConfig = {
        key: "idle",
        frames: this.anims.generateFrameNumbers(this.activeCharacter.name, {
          start: 9,
          end: 10,
        } as Phaser.Types.Animations.GenerateFrameNumbers),
        frameRate: 6,
        repeat: -1,
      };

      this.anims.create(walkDownConfig);
      this.anims.create(walkLeftConfig);
      this.anims.create(walkUpConfig);
      this.anims.create(idleConfig);

      if (this.player.anims) {
        this.player.anims.load("walkDown");
        this.player.anims.load("walkLeft");
        this.player.anims.load("walkUp");
        this.player.anims.load("idle");
      }
    }
  }

  createMap() {
    const playArea = this.map
      .createStaticLayer("world", this.tileset, 0, 0)
      .setDepth(-1);
    const worldCollision = this.map.createStaticLayer(
      "collisions",
      this.tileset,
      0,
      0
    );
    worldCollision.setDepth(-1);

    this.physics.add.collider(this.player, worldCollision);
    worldCollision.setCollisionByProperty({ collides: true });
    this.physics.world.bounds.width = this.map.widthInPixels;
    this.physics.world.bounds.height = this.map.heightInPixels;
  }

  spawnProjectile(initialPosition: Vector, targetPosition: Vector) {
    let projectile = new Projectile(this, initialPosition, targetPosition);

    this.projectiles.push(projectile);
  }
  playerAnimation = "idle";
  previousAnimation = "idle";

  destroyAllProjectiles = () => {
    for (let i = 0; i < this.projectiles.length; i++) {}
    this.projectiles.forEach((projectile) => {
      projectile.sprite.destroy();
    });
    this.projectiles = [];
  };

  update(time: number, delta: number) {
    for (let i = 0; i < this.shooters.length; i++) {
      this.shooters[i].update();
    }
    if ((this.spaceBar.isDown || this.enter.isDown) && this.gameOver) {
      this.resetGame();
    }
    for (let i = 0; i < this.projectiles.length; i++) {
      let projectile = this.projectiles[i];
      projectile.update();
      this.physics.world.overlap(projectile.sprite, this.player, (p) => {
        if (rootStore.userStore.user) {
          if (rootStore.userStore.activeChallenge) {
            rootStore.userStore.updateActiveChallengeScore(this.score);
          } else if (!rootStore.userStore.hasRole("admin")) {
            AddScore(this.score);
          }
        }
        increasePlays();

        this.player.destroy();
        this.gameOver = true;
        this.retrySprite.setVisible(true);
        this.blobSprite.setVisible(true);
        this.blobsText.setText(`Blobs: ${this.blobsEarned}`);
        this.blobsText.setVisible(true);
        this.overlay.setVisible(true);
        this.shooters.forEach((shooter) => {
          shooter.sprite.destroy();
        });
        this.shooters = [];
        projectile.sprite.destroy();
        this.destroyAllProjectiles();
      });
    }

    if (this.player.active) {
      if (this.previousAnimation !== this.playerAnimation) {
        this.player.anims.stop();
      }

      if (!this.player.anims.isPlaying) {
        this.player.anims.play(this.playerAnimation);
      }

      //this.handleMobileControls();

      if (
        !this.keyboard.D.isDown &&
        !this.keyboard.RIGHT.isDown &&
        !this.keyboard.A.isDown &&
        !this.keyboard.LEFT.isDown &&
        !this.keyboard.W.isDown &&
        !this.keyboard.UP.isDown &&
        !this.keyboard.S.isDown &&
        !this.keyboard.DOWN.isDown
      ) {
        this.previousAnimation = this.playerAnimation;
        this.playerAnimation = "idle";

        this.player.setVelocityY(0);
        this.player.setVelocityX(0);
      } else {
        if (this.keyboard.D.isDown || this.keyboard.RIGHT.isDown) {
          this.player.setVelocityX(125);
          this.player.flipX = true;
          this.previousAnimation = this.playerAnimation;
          this.playerAnimation = "walkLeft";
        } else if (this.keyboard.A.isDown || this.keyboard.LEFT.isDown) {
          this.player.setVelocityX(-125);
          this.player.flipX = false;
          this.previousAnimation = this.playerAnimation;

          this.playerAnimation = "walkLeft";
        }
        if (this.keyboard.S.isDown || this.keyboard.DOWN.isDown) {
          this.player.setVelocityY(125);
          this.previousAnimation = this.playerAnimation;
          this.playerAnimation = "walkDown";
        } else if (this.keyboard.W.isDown || this.keyboard.UP.isDown) {
          this.player.setVelocityY(-125);
          this.previousAnimation = this.playerAnimation;
          this.playerAnimation = "walkUp";
        }

        if (
          !this.keyboard.W.isDown &&
          !this.keyboard.S.isDown &&
          !this.keyboard.UP.isDown &&
          !this.keyboard.DOWN.isDown
        ) {
          this.player.setVelocityY(0);
        }

        if (
          !this.keyboard.D.isDown &&
          !this.keyboard.A.isDown &&
          !this.keyboard.RIGHT.isDown &&
          !this.keyboard.LEFT.isDown
        ) {
          this.player.setVelocityX(0);
        }
      }

      if (this.direction.X !== 0 || this.direction.Y !== 0) {
        console.log(this.direction.Y);
        this.player.setVelocityX(this.direction.X * 125);
        this.player.setVelocityY(this.direction.Y * 125);
      }
    }
  }
}

class Game extends Component {
  state = {
    initialize: false,
    game: {
      type: Phaser.AUTO,
      backgroundColor: "#125555",
      width: 256,
      height: 192,
      scale: {
        zoom: 2,
        mode: Phaser.Scale.ScaleModes.WIDTH_CONTROLS_HEIGHT,
        autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
      },

      render: {
        pixelArt: true,
      },
      physics: {
        default: "arcade",
        arcade: {
          debug: false,
        },
      },
      scene: GameplayScene,
    },
  };

  componentWillUnmount() {
    rootStore.userStore.updateActiveChallengeScore(0);
  }

  handleTouchStart = (event) => {
    console.log(event);
  };

  render() {
    const { initialize, game } = this.state;
    return (
      <div className="game">
        <IonPhaser game={game} initialize={true} />
      </div>
    );
  }
}

export default Game;
