import firebase from "firebase/app";
import { observable, action, computed } from "mobx";
import { getCharacterManager, Character, ICharacter } from "./CharacterManager";
import { CharacterEnum, IScore } from "../Models/Dodge";
import { IShopItem } from "../Components/Shop/Item";
import { acceptChallengeInvite } from "../Firebase/Client";
import Challenge from "../Models/Challenge";

require("firebase/functions");
const characterManager = getCharacterManager();

interface IUserCharacters {
  characters: Array<string>;
  activeCharacter: string;
  username: string;
  roles: Array<string>;
}

interface IPersonalScore {
  score: number;
  character: CharacterEnum;
}

enum GiftCategoryEnum {
  PRIZE = "PRIZE",
  GIFT = "GIFT",
}

enum GiftContentTypeEnum {
  CHARACTER = "CHARACTER",
  BLOBS = "BLOBS",
}
interface IGift {
  accepted: boolean;
  category: GiftCategoryEnum;
  contentName: CharacterEnum;
  contentType: GiftContentTypeEnum;
}

interface IChallengeParticipant {
  accepted: boolean;
  username: string;
  score: number;
}

interface IChallenge {
  id: string;
  acceptDeadline: any;
  deadline: any;
  active: boolean;
  participants: Array<IChallengeParticipant>;
  winner: string;
  description: string;
}

export class UserStore {
  @observable user: any;
  @observable highscore: IPersonalScore = {
    score: 0,
    character: CharacterEnum.default,
  };
  @observable gift: IGift | null = null;
  @observable blobs = 0;
  @observable roles: Array<string> = [];
  @observable username: string = "";
  @observable loggedIn: boolean = false;
  @observable characters: Array<Character> = [];
  @observable character: Character = characterManager.defaultCharacter;
  @observable showBirthdayModal: boolean = false;
  @observable loadedResources: boolean = false;
  @observable giftCharacter: string = "";
  @observable challenges: Array<Challenge> = [];
  @observable activeChallenge: Challenge | null = null;
  @observable loading: boolean = true;
  @observable itemsLoaded: number = 0;
  //credential: firebase.auth.AuthCredential;
  rootStore: any;
  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  characterLoad = () => {
    this.itemsLoaded++;
    if (this.itemsLoaded === this.characters.length - 1) {
      this.loading = false;
    }
  };

  @action
  setCharacter = (character: Character) => {
    this.character = character;

    window.postMessage({ type: "characterChange", data: character }, "*");
  };

  @action
  setShowBirthdayModal = (showBirthdayModal: boolean) => {
    this.showBirthdayModal = showBirthdayModal;
  };

  @action
  setUsername = (name: string) => {
    this.username = name;
  };

  hasRole(role: string) {
    if (this.roles) {
      return this.roles.includes(role);
    } else {
      return false;
    }
  }

  @action
  setActiveChallenge = (challengeId: string) => {
    console.log(challengeId);
    let challenge = this.challenges.find((c) => c.id === challengeId);

    if (challenge) {
      this.activeChallenge = challenge;
    } else {
      console.error("Could not find challenge");
    }
  };

  @action updateActiveChallengeScore = (score: number) => {
    if (this.activeChallenge) {
      this.activeChallenge.participants[this.user.uid].plays += 1;
      if (this.activeChallenge.chances) {
        let score = this.activeChallenge.scores.find(
          (s) => s.username === this.username
        );
        if (score && score.chancesLeft) {
          score.chancesLeft--;
        }
      }
      this.activeChallenge.updateScore(score, this.character.name);
    }
  };

  @action
  setUser = async (user: any) => {
    this.characters = [];
    this.activeChallenge = null;
    this.challenges = [];
    this.user = user;
    this.characters.push(characterManager.defaultCharacter);
    try {
      let db = window.firebase.firestore();

      let userDocRef = db.collection("users").doc(this.user.uid);
      await userDocRef.get().then(async (doc) => {
        let userData: any = doc.data();
        if (userData.characters) {
          if (userData.characters.length <= 1) {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }

        if (userData.challenges) {
          for (let i = 0; i < userData.challenges.length; i++) {
            let challengeRef = userData.challenges[i];

            await challengeRef.get().then((doc: any) => {
              this.challenges.push(
                new Challenge({ ...doc.data(), id: doc.id })
              );
            });
          }
        }
        let url = window.location.href.split("/");

        if (url[3] === "challenge") {
          this.setActiveChallenge(url[4]);
        }
      });

      db.collection("scores")
        .doc(this.user.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let data = doc.data() as IScore;

            this.highscore = {
              score: data.score,
              character: data.character,
            };
          }
        });

      let getResources = firebase
        .functions()
        .httpsCallable("getCharacterResources");
      getResources().then((result) => {
        for (let i = 0; i < result.data.resources.length; i++) {
          let character: ICharacter = result.data.resources[i];

          this.characters.push(
            new Character(
              character.name,
              character.spritesheet,
              character.frameWidth,
              character.frameHeight,
              this.characterLoad
            )
          );
        }
        if (result.data.hasBirthday) {
          this.giftCharacter = result.data.giftCharacter;
          this.setShowBirthdayModal(result.data.hasBirthday);
        }

        this.setUsername(result.data.username);
        if (result.data.blobs) {
          this.blobs = result.data.blobs;
        }
        if (result.data.roles) {
          this.roles = result.data.roles;
        } else {
          this.roles.push("user");
        }

        this.characters.forEach((c) => {
          if (c.name === result.data.activeCharacter) {
            this.character = c;
          }
        });
        this.rootStore.dodgeStore.shopItems = this.rootStore.dodgeStore.shopItems.filter(
          (c: IShopItem) => {
            if (
              this.characters.find((character) => character.name === c.name)
            ) {
              if (
                !this.characters.find(
                  (character) => character.name === c.upgradableTo
                )
              ) {
                return c.upgradableTo || c.previous;
              }
            } else {
              return !c.previous;
            }
          }
        );
        this.rootStore.dodgeStore.loadingStore = false;
        if (result.data.gifts) {
          this.gift = result.data.gifts[0];
          if (!this.gift?.accepted) {
            this.showBirthdayModal = true;
          }
        }

        if (!result.data.hasBirthday) {
          this.loadedResources = true;
        }
      });

      this.setLoggedIn(true);
    } catch (ex) {
      console.error(ex);
    }
  };

  @action
  setLoggedIn(state: boolean) {
    this.loggedIn = state;
    if (state) {
      this.login();
    }
  }

  @action
  login = () => {
    let db = window.firebase.firestore();
    let userScoreDoc = db.collection("scores").doc(this.user.uid);

    userScoreDoc.get().then((doc) => {
      if (doc.exists) {
        db.collection("scores").doc(this.user.uid).update({
          online: true,
        });
      } else {
        db.collection("scores").doc(this.user.uid).set({
          score: 0,
          username: this.username,
          character: this.character.name,
          online: true,
        });
      }
    });
  };

  @action
  logOut = () => {
    let db = window.firebase.firestore();
    if (this.user) {
      db.collection("scores")
        .doc(this.user.uid.toString())
        .update({
          online: false,
        })
        .then(() => {
          this.user = null;
          this.character = characterManager.defaultCharacter;
          this.characters = [];
          this.loadedResources = false;
          this.roles = [];
          this.setLoggedIn(false);
          firebase
            .auth()
            .signOut()
            .then(() => {});
        });
    }
  };

  signInUser = async (confirmationResult: any, code: any) => {};
}
