import { decorate, observable, action } from "mobx";

import { LoginModalButtonsEnum } from "../Components/Modals/LoginRegisterModal";
import { IScore, CharacterEnum } from "../Models/Dodge";
import { IShopItem } from "../Components/Shop/Item";
import { getCharacterShop } from "../Firebase/Client";

require("firebase/functions");

export class DodgeStore {
  @observable scores: Array<IScore> = [];
  @observable buttonType: LoginModalButtonsEnum | null = null;
  @observable shopItems: Array<IShopItem> = [];
  @observable loadingStore: boolean = true;
  rootStore: any;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  async loadCharacterShop() {
    let items: Array<IShopItem> = [];
    await getCharacterShop().then((data: any) => {
      //this.shopItems = data.data;
      let limited: Array<IShopItem> = [];
      let notLimited: Array<IShopItem> = [];
      items = data.data;
      items.sort((a, b) => (a.price.blobs > b.price.blobs ? 1 : -1));

      this.shopItems = items;
      this.loadingStore = false;
    });

    return items;
  }

  @action
  setScores = (scores: Array<IScore>) => {
    this.scores = scores;
  };

  @action
  setButtonType(buttonType: LoginModalButtonsEnum | null) {
    this.buttonType = buttonType;
  }
}
