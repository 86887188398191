import React, { useEffect, useState } from "react";
import ShopItem, { IShopItem } from "./Shop/Item";
import { observer } from "mobx-react";
import { getRootStore } from "../Store/RootStore";

const rootStore = getRootStore();

const Shop: React.FC = observer(() => {
  const [shopItems, setShopItems] = useState<Array<IShopItem>>([]);
  useEffect(() => {
    if (rootStore.dodgeStore.shopItems.length === 0) {
      rootStore.dodgeStore.loadCharacterShop();
    }
  }, []);

  return (
    <div className="container shop">
      <div className="container-header">Shop</div>
      <div className="content">
        {rootStore.dodgeStore.shopItems.map((item: IShopItem) => {
          return (
            <ShopItem
              name={item.name}
              description={item.description}
              price={item.price}
              limited={item.limited}
            />
          );
        })}
      </div>
    </div>
  );
});

export default Shop;
