import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { storage } from "firebase";

interface INotfications {
  [name: string]: boolean;
}

const Introducing: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    //Temporay solution
    let item = localStorage.getItem("notifications");

    if (item) {
      let notifications = JSON.parse(item) as INotfications;
      if (!notifications["blobs"]) {
        setOpen(true);
        notifications["blobs"] = true;

        localStorage.setItem("notifications", JSON.stringify(notifications));
      }
    } else {
      setOpen(true);
      let notifications: INotfications = { blobs: true };
      localStorage.setItem("notifications", JSON.stringify(notifications));
    }
  }, []);

  return (
    <Modal
      width="600px"
      onCancel={() => {
        setOpen(false);
      }}
      visible={isOpen}
      className="flyingblob-popup-modal"
      title={"Introducing"}
      centered={true}
      footer={null}
    >
      <div className="content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="./assets/blobsfeature.png" style={{ width: "200px" }} />
        </div>
        <div className="description">
          <p>
            <b>
              <i>Blobs</i>
            </b>{" "}
            is a new update to the site. Blobs can be used to buy new characters
            and unlock new features. You get them by simply playing the game.
            The higher you score, the more you earn! Hope you enjoy this new
            update.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default Introducing;
