import React, { useState, useEffect } from "react";
interface ICountdown {
  getTime: Function;
}
const Countdown = ({ getTime }: ICountdown) => {
  const [time, setTime] = useState<string>(getTime());

  useEffect(() => {
    if (!time.includes("days")) {
      setInterval(() => {
        setTime(getTime());
      }, 1000);
    }
  }, []);

  return <p>{time}</p>;
};

export default Countdown;
