import { observable, action, computed } from "mobx";
import {
  acceptChallengeInvite,
  updateChallengeScore,
  getDb,
  createChallenge,
  checkChallengeWinner,
} from "../Firebase/Client";
import { IScore } from "./Dodge";
import moment from "moment";
interface IChallengeParticipantData {
  accepted: boolean;
  username: string;
  score: number;
  character: string;
  plays: number;
}
export interface IChallengeParticipant {
  [participantId: string]: IChallengeParticipantData;
}
interface IChallenge {
  id: string;
  acceptDeadline: any;
  deadline: any;
  active: boolean;
  participants: IChallengeParticipant;
  winner: string;
  description: string;
  initiator: string;
  chances: number | null;
  emailInvite?: any;
  updateScore?: Function;
  checkDeadline?: Function;
  accept?: Function;
}
class Challenge implements IChallenge {
  id: string = "";
  initiator: string = "";
  emailInvite: any = null;
  @observable acceptDeadline: any;
  @observable deadline: any;
  @observable active: boolean = false;
  @observable participants: IChallengeParticipant = {};
  @observable scores: Array<IScore> = [];
  @observable winner: string = "";
  @observable description: string = "";
  @observable chances: number | null;
  deadlineDate: Date = new Date();

  constructor(challenge: IChallenge) {
    this.id = challenge.id;
    this.initiator = challenge.initiator;
    this.chances = challenge.chances;
    if (challenge.deadline?.nanoseconds >= 0) {
      this.deadline = new Date(0).setUTCSeconds(challenge.deadline.seconds);
    } else {
      this.deadlineDate = challenge.deadline.toDate();
      this.deadline = moment(challenge.deadline).toDate().getTime();
    }
    this.acceptDeadline = challenge.acceptDeadline;
    this.active = challenge.active;
    this.participants = challenge.participants;
    this.winner = challenge.winner;
    this.description = challenge.description;
    //this.emailInvite = challenge.emailInvite;
    this.emailInvite = null;
    this.checkDeadline();
    this.init();
  }

  async init() {
    if (this.id.length <= 0) {
      let users: Array<string> = [];
      Object.keys(this.participants).forEach((username) => {
        users.push(username);
      });
      await createChallenge({
        name: this.description,
        users: users,
        deadline: this.deadlineDate.getTime(),
        chances: this.chances,
        emailContent: this.emailInvite
      }).then((result) => {
        this.id = result.data.challengeId;
        this.participants = result.data.participants;
       
      });
    }
    const db = getDb();

    db.collection("dodge_challenges")
      .doc(this.id)
      
      .onSnapshot((querySnapshot) => {
        let data = querySnapshot.data();
        this.active = data?.active;
        this.winner = data?.winner;
        let participantScores: Array<IScore> = [];
        Object.values(data?.participants).forEach((participant: any) => {
          participantScores.push({
            score: participant.score,
            character: participant.character,
            username: participant.username,
            chancesLeft:
              this.chances !== null
                ? this.chances - Number(participant.plays)
                : null,
          });
        });
        this.scores = participantScores.sort((a: IScore, b: IScore) =>
          a.score < b.score ? 1 : -1
        );
      });
  }

  addParticipant() {}

  updateScore(score: number, character: string) {
    updateChallengeScore({ id: this.id, score: score, character: character })
      .then((result: any) => {})
      .catch((ex) => {
        console.error("Could not update score");
      });
  }

  checkDeadline() {
    if (this.deadline < Date.now() && this.winner.length <= 0) {
      checkChallengeWinner({ id: this.id }).then((result) => {
        if (result?.data?.winner) {
          this.winner = result.data.winner;
        }
      });
    }
  }

  pad(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getTimeLeft = () => {
    var timeleft = this.deadline - new Date().getTime();
    var days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);

    if (days > 1) {
      return `${days} days left`;
    } else {
      let date = new Date(timeleft);
      let hours = date.getUTCHours();
      let minutes = date.getUTCMinutes();
      let seconds = date.getUTCSeconds();
      if (hours === 0 && minutes === 0 && seconds === 0) {
        this.checkDeadline();
      }
      return `Ends in ${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
        seconds,
        2
      )}`;
    }
  };

  @computed get timeLeft() {
    return new Date(this.deadline - new Date().getTime());
  }

  getChancesLeft(uid: string) {
    if (this.chances) return this.chances - this.participants[uid].plays;
    return 0;
  }

  @action
  accept = () =>
    new Promise((resolve, reject) => {
      acceptChallengeInvite({
        id: this.id,
      }).then((result: any) => {
        if (result.data.accepted === true) {
          this.participants[result.data.uid].accepted = true;
          this.active = result.data.active;
          resolve();
        } else {
          reject();
        }
      });
    });
}

export default Challenge;
