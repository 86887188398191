import React, { useState, useEffect, CSSProperties } from "react";
import { getScores } from "../Firebase/Client";
import { useTable } from "react-table";
import { observer } from "mobx-react";
import { CharacterEnum, IScore } from "../Models/Dodge";
import { characterMapping } from "../Models/Characters";
import { getRootStore } from "../Store/RootStore";
import { isNullOrUndefined } from "util";

const rootStore = getRootStore();

interface IScoreComponent {
  score: IScore;
  className?: string;
}

const Score: React.FC<IScoreComponent> = ({ score, className }) => {
  let styling = {
    objectFit: "cover",
    width: "22px",
    height: "26px",
    objectPosition: `${characterMapping[CharacterEnum[score.character]]}px`,
    marginTop: "-5px",
  } as CSSProperties;
  return (
    <tr className={className ? className : ""}>
      <td className="column1">
        <img
          className="score-character"
          style={styling}
          src="/assets/characters.png"
        ></img>
        <span>{score.rank}</span>
      </td>
      <td className="column2">
        <span>
          {score.username}{" "}
        </span>
      </td>
      {!isNullOrUndefined(score.chancesLeft) ? (
        <>
          <td className="column4">
            <span>{score.chancesLeft > 0 ? score.chancesLeft : 0}</span>
          </td>{" "}
          <td className="column4">
            <span>{score.score}</span>
          </td>{" "}
        </>
      ) : (
        <td className="column3">
          <span>{score.score}</span>
        </td>
      )}
    </tr>
  );
};

const Scoreboard = observer(() => {
  //const rootStore.userStore = getrootStore.userStore();
  const [scores, setScores] = useState<any>([]);
  const data = React.useMemo(() => [], []);

  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        accessor: "score.username",
      },
      {
        Header: "Score",
        accessor: "score.score",
      },
    ],
    []
  );

  const scoreIsTopScore = () => {
    let scoresLength = rootStore.dodgeStore.scores.length;
    if (scoresLength > 0) {
      if (
        rootStore.userStore.highscore.score >
        rootStore.dodgeStore.scores[scoresLength - 1].score
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="scoreboard">
      <table className="scoreboard-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="column1">Rank</th>
            <th className="column2">Username</th>
            {rootStore.userStore.activeChallenge?.chances ? (
              <>
                <th className="column3">Chances left</th>
                <th className="column4">Score</th>
              </>
            ) : (
              <th className="column3">Score</th>
            )}
          </tr>
        </thead>
        <tbody>
          {rootStore.userStore.loggedIn && rootStore.userStore.activeChallenge
            ? rootStore.userStore.activeChallenge.scores.map(
                (score: IScore, index: number) => {
                  return <Score score={{ ...score, rank: index + 1 }} />;
                }
              )
            : rootStore.dodgeStore.scores.map(
                (score: IScore, index: number) => {
                  return <Score score={{ ...score, rank: index + 1 }} />;
                }
              )}

          {rootStore.userStore.loggedIn &&
            !rootStore.userStore.activeChallenge &&
            !scoreIsTopScore() && (
              <Score
                className="personal-score-row"
                score={
                  {
                    username: rootStore.userStore.username,
                    score: rootStore.userStore.highscore.score,
                    rank: "-",
                    character: rootStore.userStore.highscore.character,
                  } as IScore
                }
              />
            )}
        </tbody>
      </table>
    </div>
  );
});

export default Scoreboard;
{
  /*
    
      <div className="scoreboard">
      <table style={{ width: "100%" }}>
        <tr>
          <th>Rank</th>
          <th>Username</th>
          <th>Score</th>
        </tr>
        {scores.map((score: IScore, index: number) => {
          return (
            <Score
              username={score.username}
              score={score.score}
              rank={index + 1}
            />
          );
        })}
      </table>
    </div>
    
    
    */
}
