import React, { useRef, useState, useEffect, CSSProperties } from "react";
import { Modal, Button } from "antd";
import { observer } from "mobx-react";
import Confetti from "react-confetti";
import pandaPreview from "../../assets/sprites/pandaPreview.png";
import penguinPreview from "../../assets/sprites/PenguinPreview.png";
import { getRootStore } from "../../Store/RootStore";
import { characterMapping } from "../../Models/Characters";
import { CharacterEnum } from "../../Models/Dodge";
import { acceptGift } from "../../Firebase/Client";
const rootStore = getRootStore();

let step = 1;
let stepTimes = [1000, 1000, 2000, 2000];
const BirthdayPresent = observer(() => {
  const giftBoxRef = useRef<HTMLDivElement>(null);
  const characterPreview = useRef<HTMLDivElement>(null);
  const [shootConfetti, setShootConfetti] = useState(false);
  const [giftAnimationStart, setGiftAnimationStart] = useState(false);
  const [showPreviewBtn, setShowPreviewBtn] = useState(false);
  const [showYouUnlocked, setShowYouUnlocked] = useState(false);
  const [isLoading, loading] = useState(false);
  const runAnimation = () => {
    setGiftAnimationStart(true);
    incStep(step);
    if (step < 4) {
      setTimeout(function () {
        runAnimation();
      }, stepTimes[step - 1]);
    }

    ++step;
  };

  const incStep = (newStep: number) => {
    if (giftBoxRef.current) {
      giftBoxRef.current.classList.remove(`step-${newStep - 1}`);
      giftBoxRef.current.classList.add(`step-${newStep}`);
      if (newStep === 2) {
        if (characterPreview.current) {
          characterPreview.current.classList.add(`move-up`);
        }
        setShootConfetti(true);
        setShowYouUnlocked(true);
      }

      if (newStep === 3) {
        setShowPreviewBtn(true);
      }
    }
  };

  useEffect(() => {
    if (shootConfetti) {
      setTimeout(function () {
        setShootConfetti(false);
      }, 4500);
    }
  }, [shootConfetti]);
  const [imgStyle, setImgStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (rootStore.userStore.gift) {
      setImgStyle({
        objectFit: "cover",
        width: "22px",
        height: "26px",
        marginTop: "30px",
        objectPosition: `${
          characterMapping[CharacterEnum[rootStore.userStore.gift?.contentName]]
        }px`,
        transform: "scale(4)",
        imageRendering: "pixelated",
      });
    }
  }, []);

  return (
    <Modal
      width="600px"
      className="flyingblob-modal login-register"
      title={
        rootStore.userStore.giftCharacter
          ? "Happy birthday!"
          : "We've got something for you!"
      }
      visible={rootStore.userStore.showBirthdayModal}
      onCancel={() => {
        rootStore.userStore.setShowBirthdayModal(false);
      }}
      centered={true}
      footer={null}
    >
      <div style={{ height: "500px" }}>
        {shootConfetti ? <Confetti width={600} height={540}></Confetti> : null}
        {showYouUnlocked ? (
          <h3 className="unlocked">
            {rootStore.userStore.username === "aasnesk" ||
            rootStore.userStore.username === "bytesaurus"
              ? "Gratulerer med dagen!"
              : "You unlocked"}{" "}
          </h3>
        ) : null}

        <div ref={characterPreview} className="character-preview">
          {rootStore.userStore.giftCharacter ? (
            rootStore.userStore.giftCharacter === "Penguin" ? (
              <>
                <img src={penguinPreview}></img>
                <p className={"preview-name"}>Penguin</p>
              </>
            ) : (
              <>
                <img src={pandaPreview}></img>
                <p className={"preview-name"}>Panda</p>
              </>
            )
          ) : (
            <>
              <img
                style={imgStyle}
                src="./assets/characters.png"
                alt="Gift"
              ></img>
              <p className={"preview-name"}>
                {rootStore.userStore.gift?.contentName}
              </p>
            </>
          )}
        </div>
      </div>

      {showPreviewBtn ? (
        <>
          {rootStore.userStore.giftCharacter ? (
            <Button
              color="#FF5F3C"
              type="primary"
              htmlType="submit"
              className="btn-login-register"
              onClick={() => {
                rootStore.userStore.setShowBirthdayModal(false);
                rootStore.userStore.loadedResources = true;
              }}
            >
              Preview
            </Button>
          ) : (
            <Button
              color="#FF5F3C"
              type="primary"
              htmlType="submit"
              className="btn-login-register"
              onClick={() => {
                loading(true);
                acceptGift({
                  contentName: rootStore.userStore.gift?.contentName,
                }).then(() => {
                  loading(false);
                  rootStore.userStore.setShowBirthdayModal(false);
                });
              }}
            >
              {isLoading ? (
                <img height="30px" src="./assets/loading.gif" />
              ) : (
                "Accept gift"
              )}
            </Button>
          )}
        </>
      ) : (
        <div ref={giftBoxRef}>
          <div
            className="giftbox"
            onClick={() => {
              if (!giftAnimationStart) {
                runAnimation();
              }
            }}
          >
            <div className="cover">
              <div></div>
            </div>

            <div className="box"></div>
          </div>
        </div>
      )}
    </Modal>
  );
});

export default BirthdayPresent;
