import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import logo from "../assets/logo.png";
import LoginRegisterModal, {
  ILoginModal,
  LoginModalButtonsEnum,
} from "./Modals/LoginRegisterModal";
import { getRootStore } from "../Store/RootStore";

const rootStore = getRootStore();
const Header = observer(() => {
  const [login, setLogin] = useState<boolean>(false);

  return (
    <>
      <div className="header">
        <div className="left" onClick={() => (window.location.href = "/")}>
          <img src={logo} height="55px"></img>
          <p className="title">Flying Blob</p>
        </div>
        <div className="right">
          {!rootStore.userStore.loggedIn ? (
            <>
              {" "}
              <Button
                type="primary"
                ghost
                className="login"
                onClick={() => {
                  rootStore.dodgeStore.setButtonType(
                    LoginModalButtonsEnum.LOGIN
                  );
                }}
              >
                Login
              </Button>
              <Button
                type="primary"
                className="signup"
                onClick={() => {
                  rootStore.dodgeStore.setButtonType(
                    LoginModalButtonsEnum.REGISTER
                  );
                }}
              >
                Signup
              </Button>
            </>
          ) : (
            <>
              <div className="wealth">
                <img src="/assets/blob.png" className="blob-currency" />
                <p>{rootStore.userStore.blobs}</p>
              </div>
              <p className="name">{rootStore.userStore.username}</p>
              <Button
                type="primary"
                ghost
                className="login"
                onClick={() => {
                  rootStore.userStore.logOut();
                }}
              >
                Log out
              </Button>
            </>
          )}
        </div>
        {rootStore.userStore.loggedIn ? (
          <div className="profile-section"></div>
        ) : null}
      </div>
      {rootStore.dodgeStore.buttonType ? <LoginRegisterModal /> : null}
    </>
  );
});

export default Header;
