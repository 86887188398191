export interface ICharacter {
  name: string;
  spritesheet: string;
  frameWidth: number;
  frameHeight: number;
}

function getBase64Image(img: HTMLImageElement): string {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }
  return "";
}

export class Character implements ICharacter {
  name = "";
  spritesheet = "";
  base64Image: string = "";
  frameWidth = 0;
  frameHeight = 0;
  loading = true;

  constructor(
    name: string,
    spritesheet: string,
    frameWidth: number,
    frameHeight: number,
    onLoad: Function
  ) {
    this.name = name;
    this.spritesheet = spritesheet;
    this.frameWidth = frameWidth;
    this.frameHeight = frameHeight;

    let image = new Image();

    image.src = spritesheet;
    image.crossOrigin = "anonymous";

    image.onload = () => {
      this.base64Image = getBase64Image(image);
      this.loading = false;
      onLoad();
    };
  }
}
class CharacterManager {
  characters: Array<Character> = [];
  orangeBlob = new Character(
    "clarence",
    "./assets/sprites/orangeblob.png",
    20,
    24,
    () => {}
  );
  defaultCharacter = new Character(
    "default",
    "./assets/sprites/player.png",
    16,
    19,
    () => {}
  );

  constructor() {
    this.characters.push(this.orangeBlob);
    this.characters.push(this.defaultCharacter);
  }
}

let characterManager = new CharacterManager();

export const getCharacterManager = () => {
  if (characterManager) {
    return characterManager;
  } else {
    return new CharacterManager();
  }
};
