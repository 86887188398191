export enum CharacterEnum {
  "clarence" = "clarence",
  "panda" = "panda",
  "seagull" = "seagull",
  "Ninja" = "Ninja",
  "default" = "default",
  "Penguin" = "Penguin",
  "Goblin" = "Goblin",
  "Koala" = "Koala",
  "Adea" = "Adea",
  "Millie" = "Millie",
  "Pirate Skeleton" = "Pirate Skeleton",
  "Pirate Skeleton lv.1" = "Pirate Skeleton lv.1",
  "Pirate Skeleton Captain" = "Pirate Skeleton Captain",
  "UFO Alien" = "UFO Alien",
  "Snowman" = "Snowman",
  "Pumpkin Dude" = "Pumpkin Dude",
  "Ghost" = "Ghost",
  "Banan" = "Banan",
  "Nisse" = "Nisse",
  "Polar Bear" = "Polar Bear",
  "Owl" = "Owl",
}

export interface IScore {
  rank?: number | string;
  username: string;
  score: number;
  character: CharacterEnum;
  online?: boolean;
  chancesLeft?: number | null;
}
