import React, { useState, useEffect } from "react";
import { Button } from "antd";
import medal from "./medal.svg";
import { getRootStore } from "../../Store/RootStore";
import { acceptChallengeInvite } from "../../Firebase/Client";
import { observer } from "mobx-react";
import Countdown from "../Countdown";
interface IChallengeItem {
  id: string;
  name: string;
  game: string;
  first: boolean;
  participants: any;
  active: boolean;
  initiator: string;
  winner: string;
  chancesLeft: number | null;
  timeLeft: Function;
  accept: Function;
  onEnter: Function;
}
const rootStore = getRootStore();
const ChallengeItem: React.FC<IChallengeItem> = observer((props) => {
  const [isLoading, loading] = useState(false);
  return (
    <div className="challenge-item">
      <h3 className="name">{props.name}</h3>
      <div className="info">
        <p>Game: {props.game}</p>
      </div>
      {props.chancesLeft !== null && (
        <div className="chances">
          <p>
            You've got <b>{props.chancesLeft}</b> chances left
          </p>
        </div>
      )}

      {props.first === true && <img src={medal} />}
      <div className="players">
        {Object.values(props.participants).map((participant: any, index) => {
          return (
            <p>
              {index + 1}. {participant.username}: {participant.score}
            </p>
          );
        })}
      </div>

      {props.winner && !props.first ? (
        <div className="initiator">
          Winner: <i className="username">{props.winner}</i>
        </div>
      ) : !props.active && props.initiator !== rootStore.userStore.username ? (
        <div className="initiator">
          You have been invited by <i className="username">{props.initiator}</i>
        </div>
      ) : (
        !props.winner && (
          <div className="initiator">
            <Countdown getTime={props.timeLeft} />
          </div>
        )
      )}
      
      {!props.participants[rootStore.userStore.user.uid]?.accepted && props.initiator !== rootStore.userStore.user.username  ? (
        <div className="button-group">
          <Button>Decline</Button>
          <Button
            type="primary"
            onClick={() => {
              loading(true);
              props
                .accept()
                .then(() => {
                  loading(false);
                })
                .catch((ex: any) => {
                  console.error("Could not accept challenge");
                });
            }}
          >
            {isLoading ? (
              <img height="30px" src="./assets/loading.gif" />
            ) : (
              "Accept"
            )}
          </Button>
        </div>
      ) : props.active ? (
        <Button
          className="secondary"
          onClick={() => {
            props.onEnter();
            rootStore.userStore.setActiveChallenge(props.id);
          }}
        >
          Enter
        </Button>
      ) : props.winner ? (
        <p className="button-group">Game is closed</p>
      ) : (
        <p className="button-group">Waiting for other players to accept</p>
      )}
    </div>
  );
});

ChallengeItem.defaultProps = {
  first: false,
};

export default ChallengeItem;
