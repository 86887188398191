import React, {
  useRef,
  useState,
  useEffect,
  CSSProperties,
  createRef,
} from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
  AutoComplete,
} from "antd";
import { observer } from "mobx-react";
import ChallengeItem from "../Challenge/ChallengeItem";
import { getRootStore } from "../../Store/RootStore";
import { checkIfUsernameIsUnique, searchForUser } from "../../Firebase/Client";
import Challenge, { IChallengeParticipant } from "../../Models/Challenge";
import moment from "moment";

const rootStore = getRootStore();

interface IModal {
  setOpen: Function;
}

enum ViewEnum {
  CHALLENGES,
  EDIT,
  CREATE,
}

const ChallengeModal = observer((props: IModal) => {
  const [view, setView] = useState(ViewEnum.CHALLENGES);
  const submitBtnRef = createRef<any>();
  const ModalFooter = (
    <>
      <Button
        className="btn-create"
        type="primary"
        htmlType="submit"
        onClick={() => {
          if (view !== ViewEnum.CREATE) {
            setView(ViewEnum.CREATE);
          } else {
            submitBtnRef.current.click();
            setView(ViewEnum.CHALLENGES);
          }
        }}
      >
        Create
      </Button>
    </>
  );

  const ChallengesView = (
    <>
      {rootStore.userStore.challenges.map((challenge) => {
        return (
          <ChallengeItem
            id={challenge.id}
            participants={challenge.participants}
            name={challenge.description}
            game="Dodge"
            first={challenge.winner === rootStore.userStore.username}
            active={challenge.active}
            accept={challenge.accept}
            initiator={challenge.initiator}
            winner={challenge.winner}
            timeLeft={challenge.getTimeLeft}
            chancesLeft={
              challenge.chances !== null
                ? challenge.getChancesLeft(rootStore.userStore.user.uid)
                : null
            }
            onEnter={() => {
              props.setOpen(false);
            }}
          />
        );
      })}
    </>
  );

  return (
    <Modal
      width="600px"
      className="flyingblob-modal login-register"
      title="Challenges"
      visible={true}
      onCancel={() => {
        props.setOpen(false);
      }}
      centered={true}
      footer={ModalFooter}
    >
      {view === ViewEnum.CHALLENGES && ChallengesView}
      {view === ViewEnum.CREATE && <CreateChallengeView ref={submitBtnRef} />}
    </Modal>
  );
});

const CreateChallengeView = React.forwardRef((props, ref: any) => {
  const [form] = Form.useForm();
  const [emailDetected, setEmailDetected] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState<{ value: string }[]>(
    []
  );
  const [invitedUsers, setInvitedUsers] = useState([
    rootStore.userStore.username,
  ]);
  const [userError, setUserError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [chances, setChances] = useState(null);
  const onFinish = (values: any) => {
    let currentUser = rootStore.userStore.username;
    let participants: IChallengeParticipant = {};

    invitedUsers.forEach((user: string) => {
      participants[user] = {
        username: user,
        score: 0,
        character: "",
        accepted: user === currentUser,
        plays: 0,
      };
    });
    rootStore.userStore.challenges.push(
      new Challenge({
        description: values.name,
        initiator: currentUser,
        deadline: values.deadline,
        acceptDeadline: "",
        winner: "",
        id: "",
        active: false,
        participants: participants,
        chances: chances ? chances : null,
        emailInvite: emailDetected ? emailContent : null,
      })
    );
  };
  const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const addUserToInvitation = () => {
    let username = form.getFieldValue("userToAdd");
    if (emailValidator.test(username)) {
      setEmailDetected(true);
      setInvitedUsers([...invitedUsers, username]);
    } else {
      if (username.length > 0 && !invitedUsers.includes(username))
        checkIfUsernameIsUnique(username)
          .then(() => {
            setUserError("User does not exist");
          })
          .catch(() => {
            setUserError(null);
            setInvitedUsers([...invitedUsers, username]);
            form.resetFields(["userToAdd"]);
          });
    }
  };

  const removeUserFromInvitation = (username: string) => {
    setInvitedUsers(invitedUsers.filter((user) => user !== username));
  };

  const onUserSelect = (username: string) => {
    if (!invitedUsers.includes(username)) {
      setInvitedUsers([...invitedUsers, username]);
      form.resetFields(["userToAdd"]);
    }
  };

  const searchUser = async (search: string) => {
    let query = search;
    if (query.length >= 2) {
      let suggestions = await searchForUser(query);
      setUserSuggestions(suggestions);
    }
  };

  const [emailContent, setEmailContent] = useState({
    custom_message: "",
    from: "",
  });

  return (
    <div id="create_challenge">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: "",
          userToAdd: "",
          chances: 0,
          from: "",
          custom_message: "",
        }}
        onChange={(e: any) => {
          console.log(e.target.value);
          console.log(e.target);
          if (e.target.id === "user_autocomplete") {
            searchUser(e.target.value);
          }
          if (e.target.id === "chances") {
            setChances(e.target.value);
          }
        }}
        onFinish={onFinish}
      >
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Deadline" name="deadline">
          <DatePicker
            showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            format="YYYY-MM-DD HH:mm:ss"
            disabledDate={(d) => d?.isBefore(moment.now())}
          />
        </Form.Item>
        {/*<Form.Item label="Chances" name="chances">
          <div>How many chances do each player get?</div>
          <InputNumber min={0} placeholder="No limit" />
      </Form.Item>*/}
        <Form.Item label="Invite user" name="userToAdd" help={userError}>
          <div className="df input-button">
            <AutoComplete
              id="user_autocomplete"
              options={userSuggestions}
              onSelect={onUserSelect}
              placeholder="Search for user"
            />
            <Button type="primary" onClick={addUserToInvitation}>
              Add
            </Button>
          </div>
        </Form.Item>

        {emailDetected && (
          <div className="email-invitation-message">
            <h3>Email invitation </h3>
            <Form.Item label="Invitation message" name="custom_message">
              <div>Write a short message to those you invite by email</div>
              <Input
                value={emailContent.custom_message}
                onChange={(e) =>
                  setEmailContent({
                    ...emailContent,
                    custom_message: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="From" name="from">
              <div>
                Should be a name/nickname that is familiar to the recipient/s
              </div>
              <Input
                value={emailContent.from}
                onChange={(e) =>
                  setEmailContent({ ...emailContent, from: e.target.value })
                }
              />
            </Form.Item>
          </div>
        )}
        <div className="display-none">
          <Form.Item>
            <Button htmlType="submit" ref={ref}></Button>
          </Form.Item>
        </div>
      </Form>
      <h3>Participants</h3>
      {invitedUsers.map((user: string) => {
        return (
          <div className="invited-user">
            <p>{user}</p>
            {user !== rootStore.userStore.username ? (
              <span
                className="hover"
                onClick={() => {
                  removeUserFromInvitation(user);
                }}
              >
                X
              </span>
            ) : (
              <span>(You)</span>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default ChallengeModal;
