import React, { CSSProperties, useState, useEffect } from "react";
import { Scale } from "phaser";
import { characterMapping } from "../../Models/Characters";
import { CharacterEnum } from "../../Models/Dodge";
import { Button, Modal } from "antd";
import { makeOrder } from "../../Firebase/Client";
import { getRootStore } from "../../Store/RootStore";

interface IPrice {
  blobs: number;
}

export interface IShopItem {
  description: string;
  name: CharacterEnum;
  price: IPrice;
  upgradableTo?: string;
  previous?: string;
  limited?: boolean;
}
const rootStore = getRootStore();
const ShopItem: React.FC<IShopItem> = (props) => {
  const [isHovering, setHoverState] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  let imgStyle = {
    objectFit: "cover",
    width: "22px",
    height: "26px", 
    objectPosition: `${characterMapping[CharacterEnum[props.name]]}px`,
    transform: "scale(4)",
    imageRendering: "pixelated",
  } as CSSProperties;

  const onMouseOver = () => {
    setHoverState(true);
  };

  const onMouseLeave = () => {
    setHoverState(false);
  };

  return (
    <>
      <div
        className={`shop-item `}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          setConfirmModalOpen(true);
        }}
      >
        <div className={`preview ${isHovering ? "hovering" : ""}`}>
          <img
            src="/assets/characters.png"
            style={imgStyle}
            alt="Character image"
          />
        </div>
        <div className={`info ${isHovering ? "hovering" : ""}`}>
          <p className="name">{props.name}</p>
          <div className="price">
            <p>{props.price.blobs}</p>
            <img src="/assets/blob.png" />
          </div>
        </div>
        {props.limited && <div className="limited">LIMITED</div>}

        {isHovering ? (
          <p className="buy-text">
            {rootStore.userStore.characters.find((c) => c.name === props.name)
              ? "Upgrade"
              : "Buy"}
          </p>
        ) : null}
      </div>
      <BuyConfirmation
        isOpen={confirmModalOpen}
        closeModal={closeConfirmModal}
        itemName={props.name}
        itemPrice={props.price.blobs}
      />
    </>
  );
};

interface IBuyConfirmation {
  itemName: string;
  itemPrice: number;
  closeModal: VoidFunction;
  isOpen: boolean;
}

const BuyConfirmation: React.FC<IBuyConfirmation> = (props) => {
  const [isLoading, loading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  return (
    <Modal
      footer={false}
      visible={props.isOpen}
      title="Confirm"
      closable={false}
    >
      <h3>
        Unlock {props.itemName} for{" "}
        <b>{props.itemPrice}</b> blobs?
      </h3>

      <div className="df">
        <Button onClick={props.closeModal}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            loading(true);
            makeOrder({ type: "CHARACTER", id: props.itemName })
              .then((data: any) => {
                loading(false);
                if (data.data === "SUCCESS") {
                  rootStore.userStore.blobs -= Number(props.itemPrice);
                  rootStore.dodgeStore.shopItems = rootStore.dodgeStore.shopItems.filter(
                    (item) => {
                      return item.name !== props.itemName;
                    }
                  );
                  props.closeModal();
                } else if (data.data === "INSUFFICIENT_FUNDS") {
                  loading(false);
                  setError("Insufficient funds");
                } else {
                  setError("Something went wrong.");
                }
              })
              .catch((ex) => {
                loading(false);
                setError("Something went wrong.");
              });
          }}
        >
          {isLoading ? <img src="/assets/loading.gif" /> : "Confirm"}
        </Button>
      </div>
      {error && <p className="error">{error}</p>}
    </Modal>
  );
};

export default ShopItem;
