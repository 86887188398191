import { CharacterEnum } from "./Dodge";

export interface ICharacterMapping {
  [name: string]: number;
}

export const characterMapping = {
  [CharacterEnum.clarence]: 22 * 0,
  [CharacterEnum.panda]: -22 * 1,
  [CharacterEnum.seagull]: -22 * 2,
  [CharacterEnum.Ninja]: -22 * 3,
  [CharacterEnum.default]: -22 * 4,
  [CharacterEnum.Penguin]: -22 * 5,
  [CharacterEnum.Goblin]: -22 * 6,
  [CharacterEnum.Koala]: -22 * 7,
  [CharacterEnum.Adea]: -22 * 8,
  [CharacterEnum.Millie]: -22 * 9,
  [CharacterEnum["Pirate Skeleton lv.1"]]: -22 * 10,
  [CharacterEnum["Pirate Skeleton"]]: -22 * 11,
  [CharacterEnum["Pirate Skeleton Captain"]]: -22 * 12,
  [CharacterEnum["UFO Alien"]]: -22 * 13,
  [CharacterEnum.Snowman]: -22 * 14,
  [CharacterEnum["Pumpkin Dude"]]: -22 * 15,
  [CharacterEnum.Ghost]: -22 * 16,
  [CharacterEnum.Banan]: -22 * 17,
  [CharacterEnum.Nisse]: -22 * 18,
  [CharacterEnum["Polar Bear"]]: -22 * 19,
  [CharacterEnum.Owl]: -22 * 20,
} as ICharacterMapping;
