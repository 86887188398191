import React, { useState, useEffect } from "react";
import Scoreboard from "./Scoreboard";
import { Popover } from "antd";
import Game from "./Game";
import { Button } from "antd";
import { observer, useObserver } from "mobx-react";
import { LoginModalButtonsEnum } from "./Modals/LoginRegisterModal";
import BirthdayPresent from "./Modals/BirthdayPresent";
import Introducing from "./Popups/Notification/Introducing";
import Shop from "./Shop";
import { getRootStore } from "../Store/RootStore";
import CheckeredFlag from "../styles/icons/flag-checkered-solid.svg";
import Illustration from "../styles/icons/illustration.svg";
import ChallengeModal from "./Modals/ChallengeModal";
import Spotlight from "./Games/Spotlight";
import Countdown from "./Countdown";
const rootStore = getRootStore();

const CharacterList = () => {
  return useObserver(() => (
    <div className="character-list df">
      {rootStore.userStore.characters.map((c: any) => {
        return (
          <div
            className={`character-item ${
              rootStore.userStore.character.name === c.name ? "active" : ""
            }`}
            onClick={() => {
              rootStore.userStore.setCharacter(c);
            }}
          >
            <img
              className="character-tiny"
              src={c.base64Image}
              style={{
                padding: `${c.frameHeight}px 0 0 0`,
                width: `${c.frameWidth}px`,
                backgroundImage: `url(${c.base64Image})`,
              }}
            />
          </div>
        );
      })}
    </div>
  ));
};
const CharacterMenu: React.FC = () => {
  const [isVisible, setVisibility] = useState(false);
  return (
    <Popover
      placement="right"
      content={CharacterList}
      trigger="click"
      visible={isVisible}
      onVisibleChange={setVisibility}
      title="Your characters"
    >
      <div className="character-tile">
        <img
          className="character-tiny"
          src={`/assets/sprites/player.png`}
          style={{ backgroundImage: "url(/assets/sprites/player.png)" }}
        />
      </div>
    </Popover>
  );
};
interface IToolbar {
  openChallenges: Function;
}
const Toolbar = (props: IToolbar) => {
  return (
    <div id="toolbar">
      <div className="tool-tile">
        <CharacterMenu />
      </div>
      {(
        <div
          className="tool-tile"
          onClick={() => {
            props.openChallenges(true);
          }}
        >
          <img className="tool-img" src={CheckeredFlag} />
        </div>
      )}
    </div>
  );
};

const hasChancesLeft = () => {
  if (rootStore.userStore.activeChallenge?.chances) {
    if (
      rootStore.userStore.activeChallenge.getChancesLeft(
        rootStore.userStore.user.uid
      ) > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

const Body = observer(() => {
  const [challengesOpen, openChallenges] = useState(false);

  useState(() => {
    const queryString = window.location.search;

    if (queryString.includes("newuser")) {
      rootStore.dodgeStore.setButtonType(LoginModalButtonsEnum.REGISTER);
    }
  });

  return (
    <>
      <div className="body-grid body">
        {/*
        <div className="illustration-body">
          <img src={Illustration} className="illustration" />
          <div className="animated-text">
            <div className="animated-container">
              <ul className="animated-container-list">
                <li className="animated-container-list-item">Have fun! 🥳</li>
                <li className="animated-container-list-item">Compete 🎮</li>
                <li className="animated-container-list-item">Earn blobs! 🎈</li>
                <li className="animated-container-list-item">
                  Leaderboards 📈
                </li>
              </ul>
            </div>
          </div>
        </div>
        */}
        {rootStore.userStore.loggedIn &&
          rootStore.userStore.username.length > 0 && (
            <Toolbar openChallenges={openChallenges} />
          )}

        <div className="game-container">
          <div className="container-header">Dodge</div>
          <p className="description center">
            Try to stay alive for as long as possible by dodging the
            projectiles.
            <br />
            Are you able to get your name on the scoreboard?
            <br />
          </p>
          <div className="container-content">
     
            {rootStore.userStore.user &&
            rootStore.userStore.loadedResources &&
            !challengesOpen &&
            !rootStore.userStore.loading ? (
              rootStore.userStore.activeChallenge &&
              rootStore.userStore.activeChallenge.winner ? (
                <div className="game-preview">
                  <img
                    style={{ filter: "brightness(0.5)", width: "100%" }}
                    src="/assets/gameplay.png"
                  ></img>

                  <h1 className="absolute center white">Challenge is over</h1>
                </div>
              ) : hasChancesLeft() ? (
                <Game />
              ) : (
                <div className="game-preview">
                  <img
                    style={{ filter: "brightness(0.5)", width: "100%" }}
                    src="/assets/gameplay.png"
                  ></img>

                  <h1 className="absolute center white">
                    You have used all your chances
                  </h1>
                </div>
              )
            ) : (
              <div className="game-preview">
                <img
                  style={{ filter: "brightness(0.5)", width: "100%" }}
                  src="/assets/gameplay.png"
                ></img>
                <div className="login-to-play">
                  {rootStore.userStore.loggedIn ? (
                    <img
                      className="loading-gif"
                      src="/assets/loading.gif"
                    ></img>
                  ) : (
                    <p>
                      <u
                        onClick={() => {
                          rootStore.dodgeStore.setButtonType(
                            LoginModalButtonsEnum.LOGIN
                          );
                        }}
                      >
                        Login
                      </u>{" "}
                      or{" "}
                      <u
                        onClick={() => {
                          rootStore.dodgeStore.setButtonType(
                            LoginModalButtonsEnum.REGISTER
                          );
                        }}
                      >
                        signup
                      </u>{" "}
                      to play
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>{false && <h3>Pending challenges</h3>}</div>
        <div className="scoreboard-container">
          <div className="container-header">
            {rootStore.userStore.loggedIn &&
            rootStore.userStore.activeChallenge ? (
              <>
                <p>{rootStore.userStore.activeChallenge.description}</p>
                {rootStore.userStore.activeChallenge.winner ? (
                  <p>Winner: {rootStore.userStore.activeChallenge.winner}</p>
                ) : (
                  <Countdown
                    getTime={rootStore.userStore.activeChallenge.getTimeLeft}
                  />
                )}
              </>
            ) : (
              "Highscore"
            )}
          </div>
          <Scoreboard />
        </div>
        <Shop />
      </div>
      {challengesOpen && <ChallengeModal setOpen={openChallenges} />}

      {rootStore.userStore.showBirthdayModal && <BirthdayPresent />}

      {rootStore.userStore.loggedIn && <Introducing />}

      <div className="footer">
        <p>
          Using icons from
          <a target="_blank" href="https://fontawesome.com/license">
            {" "}
            font awesome
          </a>
        </p>
      </div>
    </>
  );
});

export default Body;
