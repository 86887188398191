//@ts-nocheck
import React, { Component } from "react";
import "./styles/animatedtext.scss";
import "./App.css";
import "./styles/birthday.css";
import "antd/dist/antd.css";
import Home from "./Components/Home";
require("./Firebase/Client");
class App extends Component {
  render() {
    return <Home />;
  }
}
/*
<IonPhaser game={game} initialize={initialize} />;
*/
export default App;
