//@ts-nocheck
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import { getRootStore } from "../Store/RootStore";

const rootStore = getRootStore();

var firebaseConfig = {
  apiKey: "AIzaSyAaRdMPFK8IlFlbX33AIHMoOJdJOVFVhuQ",
  authDomain: "flyingblob-49a42.firebaseapp.com",
  databaseURL: "",
  projectId: "flyingblob-49a42",
  storageBucket: "flyingblob-49a42.appspot.com",
  messagingSenderId: "515054930209",
  appId: "1:515054930209:web:437b48187471fa88705660",
};

firebase.initializeApp(firebaseConfig);
window.firebase = firebase;
let db = firebase.firestore();

export const getDb = () => {
  return db;
};

export const getCharacterShop = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("getCharacterShop");

export const makeOrder = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("makeOrder");

export const acceptChallengeInvite = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("acceptChallengeInvite");

export const updateChallengeScore = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("updateChallengeScore");

export const createChallenge = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("createChallenge");

export const checkChallengeWinner = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("checkChallengeWinner");

export const acceptGift = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("acceptGift");

db.collection("scores")
  .orderBy("score")
  .limitToLast(15)
  .onSnapshot(function (querySnapshot) {
    var scores = [];
    querySnapshot.forEach(function (doc) {
      scores.unshift(doc.data());
    });

    rootStore.dodgeStore.setScores(scores);
  });

export const getScores = () =>
  new Promise((resolve, reject) => {
    let scoresRef = db.collection("scores").orderBy("score").limitToLast(10);

    scoresRef.get().then(function (querySnapshot) {
      let scores = [] as Array<IScore>;
      querySnapshot.forEach(function (doc) {
        scores.unshift({
          username: doc.data().username,
          score: doc.data().score,
        });
      });

      resolve(scores);
    });
  });

export const checkIfUsernameIsUnique = (username: string) =>
  new Promise((resolve, reject) => {
    let usernameDoc = db.collection("usernames").doc(username);

    usernameDoc
      .get()
      .then(function (doc) {
        if (doc.exists) {
          reject(0);
        } else {
          resolve("Username is available");
        }
      })
      .catch((ex) => {
        reject(1);
      });
  });

export interface IPhoneSignup {
  signIn: Function;
  verifyCode: Function;
}

export interface ISignupHelper {
  signIn: Function;
  signUp: Function;
}

export function AddScore(score: number) {
  let userScoreDoc = db
    .collection("scores")
    .doc(rootStore.userStore.user.uid.toString());

  userScoreDoc.get().then(function (doc) {
    if (doc.exists) {
      let oldScore = doc.data().score;

      if (oldScore < score) {
        userScoreDoc.update({
          score: score,
          character: rootStore.userStore.character.name,
        });
      }
    } else {
      userScoreDoc.set({
        score: score,
        character: rootStore.userStore.character.name,
        username: rootStore.userStore.username,
      });
    }
  });
}

interface IUser {
  username: string;
  tlf: string;
}

export const registerUserName = (username: string) => {
  rootStore.userStore.username = username;
  db.collection("users").doc(rootStore.userStore.user.uid.toString()).update({
    username: username,
  });
  db.collection("usernames").doc(username).set({ exists: true });
};

export const checkIfUserHasUsername = () =>
  new Promise((resolve, reject) => {
    let userDocRef = db
      .collection("users")
      .doc(rootStore.userStore.user.uid.toString());

    userDocRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let user = doc.data() as IUser;
          if (user.username.length <= 0) {
            reject(0);
          } else {
            rootStore.userStore.setUsername(user.username);

            resolve();
          }
        } else {
          console.error("Something went wrong");
          reject(1);
        }
      })
      .catch(function (error) {
        reject(1);
      });
  });

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    rootStore.userStore.setUser(user);
    rootStore.userStore.setLoggedIn(true);
  } else {
  }
});

export class SignupHelper implements ISignupHelper {
  signIn = (email: string, password: string) =>
    new Promise((resolve, reject) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
              rootStore.userStore.setUser(result.user);
              rootStore.userStore.setLoggedIn(true);
              let userDocRef = db
                .collection("users")
                .doc(rootStore.userStore.user.uid.toString());

              userDocRef
                .get()
                .then(function (doc) {
                  if (doc.exists) {
                    let user = doc.data() as IUser;
                    if (user.username.length <= 0) {
                      reject(0);
                    } else {
                      rootStore.userStore.setUsername(user.username);

                      resolve();
                    }
                  } else {
                    console.error("Something went wrong");
                    reject(1);
                  }
                })
                .catch(function (error) {
                  reject(1);
                });
              resolve();
            })
            .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              reject(errorMessage);
              // ...
            });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          reject(errorMessage);
        });
    });

  signUp = (username: string, email: string, password: string) =>
    new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          //result.user?.sendEmailVerification();
          rootStore.userStore.setUser(result.user);

          rootStore.userStore.setLoggedIn(true);
          db.collection("users").doc(result.user?.uid.toString()).set({
            username: username,
            email: result.user?.email,
          });
          registerUserName(username);
          resolve();
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          reject(errorMessage);
          // ...
        });
    });
}

export class PhoneSignup implements IPhoneSignup {
  confirmationResult: firebase.auth.ConfirmationResult;
  constructor() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.onSignInSubmit();
        },
      }
    );
  }

  signIn = (phoneNumber: number) =>
    new Promise((resolve, reject) => {
      var appVerifier = window.recaptchaVerifier;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          resolve(confirmationResult);
          window.confirmationResult = confirmationResult;
        })
        .catch(function (error) {
          // Error; SMS not sent
          // ...
          reject(error);
        });
    });

  verifyCode = async (code: number) =>
    new Promise((resolve, reject) => {
      try {
        var credential = firebase.auth.PhoneAuthProvider.credential(
          window.confirmationResult.verificationId,
          code
        );

        firebase
          .auth()
          .signInWithCredential(credential)
          .then((result) => {
            rootStore.userStore.setUser(result.user);
            if (result.additionalUserInfo?.isNewUser) {
              db.collection("users").doc(result.user?.uid.toString()).set({
                username: "",
                phone: result.user?.phoneNumber,
              });
            }
            resolve("");
          });
      } catch (ex) {
        console.error(ex);
        reject();
      }
    });
}

export async function searchForUser(searchString: string): { value: string }[] {
  const usernameDocuments = await db
    .collection("usernames")
    .where("name", ">=", searchString.toLowerCase())
    .where("name", "<=", searchString.toLowerCase() + "\uf8ff");
  let users = [];
  await usernameDocuments
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        users.push({ value: doc.id });
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });

  return users;
}
